import styled from "styled-components"
import MenuBG from "../images/menu-bg.jpg"
import HomeBanner from "../images/home.jpg"
const SiteContainer = styled.div`
  body,
  html {
    font-family: "Open Sans", sans-serif;
  }
  h1,
  h2,
  h3,
  h4 {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: center;
  }
  p,
  a,
  li {
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 14px;
  }
  button {
    outline: 0;
    &:focus {
      box-shadow: none;
    }
  }
  .center {
    text-align: center;
  }
  header {
    .logo {
      width: 50%;
      img {
        width: 80px;
      }
    }
    .navbar {
      background: #fff;
      padding-bottom: 0;
      -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
      .nav-item {
        a {
          text-transform: uppercase;
          font-size: 16px;
          font-weight: 400;
          margin: 0 10px;
        }
      }
    }
  }
  .flex {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .gh-button-ifrm {
      margin: auto;
  }
  .home-banner {
    background: url(${HomeBanner}) center center no-repeat;
    background-size: cover;
    width: 100%;
    height: 100vh;
    position: relative;
    div {
      position: absolute;
      right: 0;
      left: 0;
      top: 50%;
      bottom: 0;
      margin: auto;
      h1 {
        color: #000;
        z-index: 99;
        font-weight: 300;
        font-size: 60px;
      }
      p {
        text-align: center;
        button {
          background: red;
          color: #fff;
          border: 0;
          padding: 10px 30px;
          font-size: 24px;
          &:hover {
            background: #000;
          }
        }
      }
    }
  }
  section {
    padding: 40px 20px;
    &#online {
      background: #ebe0c8;
    }
    &#menu {
      background: url(${MenuBG}) right top no-repeat #d3dbb5;
    }
  }
  .card {
    background: #fff;
    border: 0;
    border-radius: 0;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  }
  .card-header {
    background: none;
    border: 0;
    border-radius: 0;
    button {
      color: #7d8c48;
      text-transform: uppercase;
      font-weight: 700;
      text-decoration: none;
      &:hover {
        color: #474646;
      }
    }
  }
  .card-body {
    padding-left: 35px;
    p {
      &.meat {
        width: 100%;
        max-width: 500px;
        span {
          float: right;
        }
      }
    }
    h3 {
      color: #7e8a47;
    }
    h4 {
      text-align: left;
      font-size: 16px;
      @media screen and (max-width: 767px) {
        font-size: 14px;
      }
      margin-bottom: 5px;
      span {
        float: right;
      }
    }
  }
  footer {
    background: #000;
    padding: 40px 20px;
    p {
      color: #fff;
      font-size: 13px;
      text-transform: uppercase;
      letter-spacing: 1px;
      text-align: center;
      font-weight: 400;
    }
  }
`

export default SiteContainer
