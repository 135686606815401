import PropTypes from "prop-types"
import React from "react"
import Logo from "../images/logo.png"
const Header = () => (
  <header>
    <nav className="navbar fixed-top navbar-expand-md navbar-light bg-light">
      <div className="container">
        <div className="logo">
          <a href="/">
            <img src={Logo} alt="Thai &amp; I Shrewsbury" />
          </a>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <div className="mr-auto navbar-nav">
            <div className="nav-item">
              <a href="/" className="nav-link">
                Home
              </a>
            </div>
            <div className="nav-item">
              <a href="#about" className="nav-link">
                About
              </a>
            </div>
            <div className="nav-item">
              <a href="#menu" className="nav-link">
                Menu
              </a>
            </div>
            <div className="nav-item">
              <a href="#hours" className="nav-link">
                Hours
              </a>
            </div>

            <div className="nav-item">
              <a href="https://thaiandirestaurant.dine.online/" className="nav-link">
                ORDER FOR DELIVERY
              </a>
            </div>
            <div className="nav-item">
              <a href="https://www.toasttab.com/thai-i/v3" className="nav-link">
                ORDER FOR PICK UP
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
